import React from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Privacy = (props) => {
  const privacyMarkdown = props.data.markdownRemark;

  return (
    <Layout bodyClass="page-about">
      <SEO
        title={privacyMarkdown.frontmatter.title}
        description={privacyMarkdown.frontmatter.SEODescription}
        path={privacyMarkdown.frontmatter.path} />
      <Container className="mt-5">
        <h1>{privacyMarkdown.frontmatter.title}</h1>
        <div className="content" dangerouslySetInnerHTML={{ __html: privacyMarkdown.html }} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query PrivacyQuery {
    markdownRemark(fileAbsolutePath: {regex: "/privacy.md/"}) {
      frontmatter {
        title
        SEODescription
        path
      }
      html
    }
  }
`;

export default Privacy;
